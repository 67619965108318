<template>

            <a href="/meyrowitz" class="arrow-back">
                <img src="../../../../public/assets/images/backmeyro.png" width="50">
            </a>
            <img class="full" @click="fullscreenToggle" v-if="fullScreen==false" src="../../../../public/assets/images/full.png" width="40" />
            <img class="full" v-else @click="fullscreenToggle" src="../../../../public/assets/images/cross.svg" width="60" />
                <img v-if="displayTextPreview==true" src="../../../../public/assets/collectionglass/space.gif" class="space-gravure-bg" />
                
                <div v-show="displayTextPreview==true" class="text_preview" >
                    <div class="gravure-container">
                        <div class="gravure_preview" :style="'margin-left:'+glass.gravure_position_x_new+'%;margin-top:'+glass.gravure_position_y+'%;transform:rotate('+glass.gravure_position_angle+'deg);'" :class="{glass26:idGlass==26 || glass.gravure_position_x=='op',glass25:glass.gravure_position_x=='louis'}">
                            {{gravure}}
                        </div>
                        <img class="gravure_img" :src="calculateBranchPhoto()" />
                    </div>
                </div>
                
                <div v-show="displayTextPreview==false && loadingPhotos==false && photosLoaded && allIsLoaded" class="content-image-product" :class="{four:fiveSlides==false}">
                   
                    
                
                    <img v-if="imagesLoading==true" src="../../../../public/assets/collectionglass/space.gif" class="preview_photo photo2 loading" />
                    <img src="../../../../public/assets/collectionglass/space.gif" v-if="slider==null" />
                    
                    <div class="my-slider" id="slider-content" >
                       <div class="carousel-cell" v-for="(scene, indexscene) in photoDatas" >

                        <content-loader   viewBox="0 0 300 300" class="loading-image-product" v-if="layersLoaded[indexscene]<scene.length" >
                        </content-loader>
                           
                          
                            <img src="../../../../public/assets/collectionglass/space.gif" class="space" />


                            
                                <div class="img">

                                  
                                    <img v-for="(layer,indexlayer) in scene" :key="indexlayer" :style="'opacity:'+getOpacityElement(indexscene,indexlayer)+';'" :src='calculatePhoto(layer,indexscene)' :class="{img:true,'render-layer2':indexlayer>0,'render-top':(indexlayer==4 && idGlass==22 && indexscene=='scene5')}" @load="finishLoadLayer(indexscene)" />

                                </div>
                           
                        </div>
                            

                      

                    </div>
                    <div id="prev"><img src="../../../../public/assets/images/arroww.png" width="10" /></div>
                    <div id="next"><img src="../../../../public/assets/images/arroww.png" width="10" /></div>
                </div>

                <!-- <div id="slider-content-slides" class="slider-content-hide">
                    <div class="carousel-cell" v-for="(photo,index) in photos" :key="index" ><img :src="photo" /></div>
                </div> -->
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom';
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import { ContentLoader } from 'vue-content-loader'

export default {
    name: "CollabSectionLeft",
    props: ["fullScreen", "loaded","photosLoaded","displayTextPreview","gravure","photo","photos",
    "fiveSlides",
    "photoDatas",
    "photoHoverDatas",
    "loadingGlassOptions",
    "idGlass",
    "loadingGlassTypes",
    "loadingPhotos",
    "loadingScans",
    "currentBranchPhoto",
    "currentBranchPhotoPresets",
    "loadingSizes",
    "loadingTintCategories",
    "loadingTints",
    "loadingColors",
    "loadingTextures",
    "allIsLoaded",
    "loadingTextureStyles",
    "loadingRecommendedSize",
    "loadingReviews",
    "loadingGlass",
    "loadingBranches",
    "layersLoaded",
    "alreadyLoaded",
  "glass",
    "urlPhotoHover","orderPhotoHover",
    "hoverPhotoDisplay","urlPhotoSlider",
    "slider"],
    components: {
        'inner-image-zoom': InnerImageZoom,ContentLoader
    },
    methods: {

        startLoadLayer(indexscene){

            this.$emit('startLoadLayerEv',indexscene)
        },
      
        finishLoadLayer(indexscene){

            this.$emit('finishLoadLayerEv',indexscene)
        },
        hideHoverPhoto() {
            this.$emit('hideHoverPhotoEv')
        },
        calculateBranchPhoto() {

            if(this.currentBranchPhotoPresets)
            {

            
                
                let hue = this.currentBranchPhotoPresets.hue_app ? this.currentBranchPhotoPresets.hue_app : 100
                let saturation = this.currentBranchPhotoPresets.saturation ? this.currentBranchPhotoPresets.saturation : 100
                let brightness = this.currentBranchPhotoPresets.brightness ? this.currentBranchPhotoPresets.brightness : 100


                let url
                if(brightness==100 && hue==100 && saturation==100)
                {
                url = 'https://api.sens-eye.fr/render_parts/'+this.currentBranchPhoto
                }
                else {
                url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+this.currentBranchPhoto+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
                }

                //this.$emit('calculatePhotoEv',0)

                return url
            }
            else
            {
                return null
            }
        },
        calculatePhoto(layer_info,index) {
            
            //this.startLoadLayer(index)
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        getBrightnessElementHover(layer) {
           
            if(this.photoHoverDatas.presets['layer'+layer].brightness != null) {
                return this.photoHoverDatas.presets['layer'+layer].brightness/100
            }
            else {
                return 1
            }
        },
        getHueElementHover(layer) {
            
            if(this.photoHoverDatas.presets['layer'+layer].hue != null) {
                return this.photoHoverDatas.presets['layer'+layer].hue/1.7
            }
            else {
                return 0
            }
        },
        getSaturationElementHover(layer) {
           
            if(this.photoHoverDatas.presets['layer'+layer].saturation != null) {
                return this.photoHoverDatas.presets['layer'+layer].saturation/100
            }
            else {
                return 1
            }
        },
        getOpacityElementHover(layer) {
          
            if(this.photoHoverDatas.presets['layer'+layer].opacity != null) {
                return this.photoHoverDatas.presets['layer'+layer].opacity/100
            }
            else {
                return 1
            }
        },

        getBrightnessElement(index, layer) {
            
            if(this.photoDatas[index][layer].presets.brightness != null) {
                return this.photoDatas[index][layer].presets.brightness/100
            }
            else {
                return 1
            }
        },
        getHueElement(index, layer) {

            if(this.photoDatas[index][layer].presets.hue != null) {
                return this.photoDatas[index][layer].presets.hue/1.7
            }
            else {
                return 0
            }
           
        },
        getSaturationElement(index, layer) {
            if(this.photoDatas[index][layer].presets.saturation != null) {
                return this.photoDatas[index][layer].presets.saturation/100
            }
            else {
                return 1
            }
          
        },
        getOpacityElement(index, layer) {
            if(this.photoDatas[index][layer].presets.opacity != null) {
                return this.photoDatas[index][layer].presets.opacity/100
            }
            else {
                return 1
            }
           
        },
        fullscreenToggle()
        {
            this.$emit('fullScreenToggleEv')
        },
        imageLoaded(index) {
            if(index==0) {this.image0Loaded=true}
            if(index==1) {this.image1Loaded=true}
            if(index==2) {this.image2Loaded=true}
            if(index==3) {this.image3Loaded=true}
            if(index==4){ this.image4Loaded=true}


            if(this.image0Loaded==true && this.image1Loaded==true && this.image2Loaded==true && this.image3Loaded==true) {
                if(this.fiveSlides==false){
                    
                    this.imagesLoading=false
                }
                else if(this.image4Loaded==true) {
                  
                    this.imagesLoading=false
                }
            }
        }
    },
    data() {
        return {
            
            imageLoading:false,
            imagesLoading:false,
            image0Loaded:false,
            image1Loaded:false,
            image2Loaded:false,
            image3Loaded:false,
            image4Loaded:false,
        }
    },
    watch: {
        'urlPhotoHover': function() {
            this.imageLoading = true;
        },
        'urlPhotoSlider': function() {
            this.imagesLoading = true;
            this.image0Loaded = false;
            this.image1Loaded = false;
            this.image2Loaded = false;
            this.image3Loaded = false;
            this.image4Loaded = false;
        },
       
    },
    

}
</script>

<style scoped>
.arrow-back
{
    position:absolute;
    top:20px;
    left:20px;
    z-index: 1;
  
}
.loading-image-product
{
    position:absolute;
    z-index:3;
}
.img {
    position:relative;
}
.render-layer2 {
    position:absolute !important;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 2;
}
.render-layer3 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 3;
}
.render-top 
{
    z-index: 10;
}
.render-layer4 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 4;
}
.render-layer5 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 5;
}
.render-layer6 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 6;
}
.render-layer7 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 7;
}
.render-layer8 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 8;
}
.space-gravure-bg {
    width:100%;
}
#prev img
{
    transform:rotate(-270deg) ;
}
#next img
{
    transform:rotate(90deg) !important;
}
#prev[aria-disabled=true] {
  opacity:0;
}
#next[aria-disabled=true] {
  opacity:0;
}

.preview_photo.loading {
    backdrop-filter: blur(30px);
    background:rgba(248,248,248,0.5) !important;
}
.preview_photo {
    position:absolute;
    left:0;
    top:-11px;
    width:100%;
    object-fit: contain;
    z-index:1;
    background:#F8F8F8;
    transition:all 0.3s;
    height:100%;

}
#left-content {
    overflow: hidden;
}
.content-image-product {
    overflow: hidden;
}
@media screen and (max-width:760px)
{
    .arrow-back
    {
        width:30px !important;
    }
}
</style>